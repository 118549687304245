import axios from 'axios';
import Agent from 'agentkeepalive';

const httpAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000,
  keepAlive: true,
  withCredentials: true,
  credentials: 'include'
});

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL,
  httpAgent
});

export default instance;
