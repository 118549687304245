import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import useSWR from 'swr';

import { LoadingSpinner } from 'spacer-ui-kit';

const ForgotPassword = lazy(() => import('pages/forgot-password'));
const ResetPassword = lazy(() => import('pages/reset-password'));
const Dashboard = lazy(() => import('pages/dashboard'));
const Login = lazy(() => import('pages/login'));

const App = () => {
  const { data, error } = useSWR('/auth/me', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  if (!data && !error) {
    return <LoadingSpinner header="Please wait" />;
  }

  if (data && data.sso && !window.location.href.includes(data.sso)) {
    window.location.replace(data.sso);
  }

  return (
    <>
      <Suspense fallback={<LoadingSpinner header="Please wait" />}>
        <Switch>
          <Route
            path="/login"
            render={() => <Login />}
          />
          <Route
            path="/forgot-password"
            render={() => <ForgotPassword />}
          />
          <Route
            path="/reset-password"
            render={() => <ResetPassword />}
          />
          <Route
            path="/"
            render={() => <Dashboard />}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default App;
